<template>
	<v-app>
		<v-navigation-drawer v-model="drawer" app clipped>
			<v-select v-if="currentUser.IsAdmin" v-model="aziendaCorrente" :disabled="disattivazioneSelectCambioAzienda" :items="aziende" item-value="Id" class="ml-4 mr-4" item-text="Nome" :label="gTranslate('Azienda corrente', {section:'MASTER'})" @change="cambiaAziendaCorrente"></v-select>
			<pre>{{ setupErrorsDecodifiche }}</pre>
			<v-list dense>
				<template v-for="(item, idx) in appMenu">
					<v-list-item v-if="!item.Separator && !item.AdminOnly" :key="idx" :to="item.Route">
						<v-list-item-icon><v-icon>{{ item.Icon }}</v-icon></v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title><translated-text section="MASTER" :res="'menu.' + item.Name">{{ item.Description }}</translated-text></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-list-item v-if="!item.Separator && item.AdminOnly && currentUser.IsAdmin" :key="idx" :to="item.Route">
						<v-list-item-icon>
							<v-icon color="warning">
								{{ item.Icon }}
							</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title><translated-text section="MASTER" :res="'menu.' + item.Name">{{ item.Description }}</translated-text></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<v-divider v-if="item.Separator" :key="idx+'div'" />
				</template>

				<v-list-item to="/impostazioni/0">
					<v-list-item-icon>
						<v-icon>mdi-format-list-checks</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title><translated-text section="MASTER" :res="'menu.impostazioni'">Impostazioni</translated-text></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider />
			</v-list>

			<v-footer color="transparent" padless>
				<v-container fluid>
					<v-row v-if="viewTranslatableElements" :dense="compactVisualization">
						<v-col cols="12">
							<v-card elevation="0" outlined tile :color="darkTheme ? 'deep-orange darken-4' : 'deep-orange'">
								<v-card-title>Translate debug</v-card-title>
								<v-card-text>
									<v-list>
										<v-list-item>
											<v-list-item-action><v-switch v-model="showKeysTranslatableElments"></v-switch></v-list-item-action>
											<v-list-item-content>
												<v-list-item-title>Show Keys</v-list-item-title>
												<v-list-item-subtitle></v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card-text>
								<v-card-subtitle>Attiva la visualizzazione delle chiavi degli elementi traducibili</v-card-subtitle>
							</v-card>
						</v-col>
					</v-row>					
					<v-row :dense="compactVisualization">
						<v-col cols="12" class="grey--text" style="opacity:0.5; ">
							<v-sheet elevation="2" outlined rounded>
								<div style="transform:scale(0.9)" class="mx-auto">
									<div class="text-subtitle-2 mb-1 mt-1">{{ appSetup.appName }} </div>
									<div class="text-caption">UI v{{ uiVersion }} </div>
									<div class="text-caption">API {{ apiVersion.AppVersion }} - {{ apiVersion.AppDate }}</div>
								
									<div class="mt-2">
										<v-chip v-if="uiVersionBetaTag" class="mr-1" x-small color="primary" outlined label>{{ uiVersionBetaTag }}</v-chip>  
										<v-chip v-if="appSetup.isTest" class="mr-1" x-small color="error" outlined label>TEST</v-chip> 
										<v-chip v-if="appSetup.isDevelop" class="mr-1" x-small color="accent" outlined label>DEV</v-chip>
										<v-chip x-small color="success" outlined label>{{ apiVersion.AppBranch }}</v-chip>									
									</div>
								</div>
							</v-sheet>
						</v-col>
					</v-row>
				</v-container>
			</v-footer>
		</v-navigation-drawer>

		<v-app-bar app clipped-left :color="appSetup.appColor" dense>
			<v-app-bar-nav-icon @click.stop="drawer = !drawer" />
			<v-toolbar-title class="mr-12 align-center">
				<span class="title">{{ currentUser.AziendaCorrente.Nome }} HRM</span>
				<div v-if="viewDebugElements" class="d-inline-block ml-5">
					<v-chip small class="d-flex d-sm-none elevation-2" color="light-blue darken-2">
						XS
					</v-chip>
					<v-chip small class="d-none d-sm-flex d-md-none elevation-2" color="teal darken-2">
						SM
					</v-chip>
					<v-chip small class="d-none d-md-flex d-lg-none elevation-2" color="green darken-2">
						MD
					</v-chip>
					<v-chip small class="d-none d-lg-flex d-xl-none elevation-2" color="lime darken-2">
						LG
					</v-chip>
					<v-chip small class="d-none d-xl-flex elevation-2" color="yellow darken-2">
						XL
					</v-chip>
				</div>
				<span v-if="viewDebugElements" class="d-inline-block ml-5 text-overline">lang:{{ currentUser.LinguaCorrente }} / id.Az:{{ aziendaCorrente }}</span>
			</v-toolbar-title>
			<div class="flex-grow-0" />
			<v-spacer />
			<div class="d-flex">
				<v-gravatar class="img-circle mr-3 pa-1" :email="currentUser.Email" />
				<div class="d-flex flex-column mr-3">
					<div class="text-no-wrap body-1">
						{{ currentUser.Nome }} {{ currentUser.Cognome }}
					</div>
					<div class="caption">
						{{ currentUser.Email }}
					</div>
				</div>
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-btn dark icon v-on="on">
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item to="/logout">
							<v-list-item-title>
								<v-icon left>
									mdi-logout
								</v-icon><translated-text section="MASTER" res="menudx.esci">Esci</translated-text>
							</v-list-item-title>
						</v-list-item>
						<v-list-item @click="toggleTheme">
							<v-list-item-title>
								<v-icon v-if="darkTheme" left>
									mdi-lightbulb-on-outline
								</v-icon>
								<v-icon v-else left>
									mdi-lightbulb-on
								</v-icon>								
								<translated-text section="MASTER" res="menudx.tema">Cambia tema</translated-text>
							</v-list-item-title>
						</v-list-item>
						<v-list-item to="/impostazioni/0">
							<v-list-item-title>
								<v-icon left>
									mdi-format-list-checks
								</v-icon><translated-text section="MASTER" res="menudx.impostazioni">Impostazioni</translated-text>
							</v-list-item-title>
						</v-list-item>
						<v-list-item to="/impostazioni/1">
							<v-list-item-title>
								<v-icon left>
									mdi-shield-lock-outline
								</v-icon><translated-text section="MASTER" res="menudx.password">Cambia password</translated-text>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</v-app-bar>

		<!-- <v-main :class="isAdminPage ? (darkTheme ? 'brown darken-4' : 'orange lighten-5') : ''"> -->
		<v-main>
			<!-- necessario per mantenere invariato lo stato della lista persone durante il drilldown su persona -->
			<keep-alive include="persone-page">
				<router-view />
			</keep-alive> 
		</v-main>

		<v-snackbar v-model="setupErrorsDecodifiche" app top center :timeout="-1" color="warning">
			<h2>{{ setupErrorsDecodifiche }}</h2>
		</v-snackbar>
	</v-app>
</template>

<script>
export default {
	data: () => ({
		drawer: null,
		isCreated: false,
		aziendaCorrente: 1,
		// flag sottoposto a timer per tenere disattivata la selezione azienda per qualche secondo dopo il login
		disattivazioneSelectCambioAzienda: true,
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		uiVersion() { return this.$store.getters['uiVersion'] },
		uiVersionBetaTag() { return this.$store.getters['uiVersionBetaTag'] },
		apiVersion() { return this.$store.getters['apiVersion'] },
		apiDate() { return this.$store.getters['apiDate'] },
		darkTheme() { return this.$vuetify.theme.dark },
		keyvalues() { return this.$store.getters['keyvalues'] },
		isLoggedIn() { return this.$store.getters['isLoggedIn'] },
		appMenu() { return this.$store.getters['appMenu'] },
		currentUser() { return this.$store.getters['currentUser'] },
		aziende() { return this.$store.getters['aziende'] },
		setupErrorsDecodifiche() { return this.$store.getters['setupErrorsDecodifiche'] },
		isAdminPage() { return this.$store.state.route.path.startsWith('/admin') },
		viewTranslatableElements() { return this.$store.getters['viewTranslatableElements'] },
		viewKeyTranslatableElements() { return this.$store.getters['viewKeyTranslatableElements'] },
		showKeysTranslatableElments: {
			get() {
				return this.viewKeyTranslatableElements
			},
			// eslint-disable-next-line no-unused-vars
			set(value) {
				this.$store.dispatch('toggleShowKeyTranslatableElements')
			}
		},
	},
	mounted() {
		this.aziendaCorrente = this.currentUser.AziendaCorrente.Id
		// imposta la lingua di vuetify
		this.$vuetify.lang.current = this.currentUser.LinguaCorrenteShort
		// imposta il tema
		if(this.$store.getters['keyvalues']){ 
			if(this.$store.getters['keyvalues']['theme.dark']) {
				var val1 = this.$store.getters['keyvalues']['theme.dark'] === 'true'
				this.$vuetify.theme.dark = val1 
			}
			if(!!this.$store.getters['keyvalues']['debugmode.on']) {
				var val2 = this.$store.getters['keyvalues']['debugmode.on'] === 'true'
				if(this.viewDebugElements !== val2) this.$store.dispatch('toggleDebugElements')
			}
		}
		// il flag che attiva il selettore aziende (solo per gli admin) viene sbloccato dopo qualche secondo per essere sicuri che tutti i caricamenti post-login siano stati completati
		if(this.currentUser.IsAdmin) {
			setTimeout(() => { this.disattivazioneSelectCambioAzienda=false }, 10000)
		}
		
	},
	methods: {
		toggleTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark
			this.$store.dispatch('toggleTheme', this.$vuetify.theme.dark)
		},
		//
		cambiaAziendaCorrente() {
			this.$store.dispatch('cambiaAziendaCorrente', this.aziendaCorrente)
		},
	}
}
</script>

<style scoped lang="less">

.img-circle {
	height: 45px;
	width: 45px;
}

</style>